import React from 'react';
import CtaLink from '../components/CTA/CTALink';

export default function NotFound() {
  return (
    <main className="lg:mt-15 lg:min-h-[40vh]">
      <div className="flex justify-center mt-8 pb-30">
        <div className="section-column">
          <header className="mb-8 lg:mb-15">
            <h1 className="text-2xl text-center">
              Oops - vi fant ikke siden du leter etter
            </h1>
          </header>
          <div className="rounded-[50%] bg-off-white w-30 h-30 mx-auto mb-2 object-cover">
            <img
              className="w-full h-full"
              src="/femaleDoctor.png"
              alt="doctor"
            />
          </div>
          <div className="px-5 mt-8 text-center ">
            Siden du prøver å nå har enten blitt flyttet eller fjernet. Du kan
            komme tilbake til forsiden via linken under. Vennligst kontakt oss
            dersom dette skjer igjen.
          </div>
          <div className="mt-8 text-center rounded-[50">
            <CtaLink margin="0.5rem 0 0" to=" ">
              Til forsiden
            </CtaLink>
          </div>
        </div>
      </div>
    </main>
  );
}
